
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.modal-user-info {
  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 16px;
  }

  &__label {
    width: 118px;
    flex-shrink: 0;
    color: $color-grayscale-60;
    margin-right: 24px;
  }

  &__value {
    width: calc(100% - 118px);

    .esmp-link {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
